import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MoveData } from '@app/move/classes/move-data.class';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { CheckInput } from '@smooved/ui';

@Component({
    selector: 'app-water-document-moving-address-modal',
    template: `
        <app-info-modal
            *ngIf="{
                move: (move$ | async),
                isRealEstateAgentOrAdmin: (authenticationSandbox.isRealEstateAgentOrAdmin$ | async),
                isRealEstateAgent: (authenticationSandbox.isRealEstateAgent$ | async),
            } as vm"
        >
            <ng-container modal-header>
                <h5>{{ 'WATER.DOCUMENT.MODAL.TITLE' | translate }}</h5>
                <p class="u-color-muted u-font-size-small">{{ vm.move?.user?.formattedMovingAddressWithoutCountry }}</p>
            </ng-container>

            <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit()">
                <app-check-input
                    [custom]="false"
                    [option]="waterCollectiveMeterOption"
                    formControlName="waterCollectiveMeter"
                ></app-check-input>

                <div class="u-text-align-right u-w100p">
                    <app-previous-submit
                        [showPrevious]="false"
                        *ngIf="!data.readOnly || vm.isRealEstateAgentOrAdmin"
                        submitTranslationLabel="SEND"
                    ></app-previous-submit>
                </div>
            </form>
        </app-info-modal>
    `,
    styleUrls: ['./water-document-moving-address.modal.scss'],
})
export class WaterDocumentMovingAddressModal extends MoveData implements OnInit {
    public form = this.formBuilder.group({
        waterCollectiveMeter: [
            {
                value: null,
                disabled: !!this.data.readOnly,
            },
        ],
    });

    public waterCollectiveMeterOption: CheckInput<boolean> = {
        id: 'water-collective-meter',
        name: 'water-collective-meter',
        value: true,
        label: this.translateService.instant('WATER.DOCUMENT.MODAL.COLLECTIVE_METER'),
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalDataMove,
        private formBuilder: UntypedFormBuilder,
        public dialogRef: MatDialogRef<any>,
        private uiSandbox: AppUiSandbox,
        protected moveSandbox: MoveSandbox,
        private translateService: TranslateService,
        public authenticationSandbox: AuthenticationSandbox
    ) {
        super(moveSandbox, data?.patchedSubject);
    }

    public ngOnInit(): void {
        this.updateMove(this.data?.move);
        this.waterCollectiveMeterFormGroup().patchValue(this.data?.move?.waterCollectiveMeter);
    }

    public onSubmit(): void {
        if (this.form.valid) {
            this.moveOnce$.subscribe((moveOnce) => {
                this.moveSandbox.patchProperty(
                    'waterCollectiveMeter',
                    this.waterCollectiveMeterFormGroup().value,
                    true,
                    (move) => {
                        this.uiSandbox.hideLoadingOverlay();
                        this.updateMove(move);
                        this.moveSandbox.updatedMoveTrigger.next();
                        this.dialogRef.close(true);
                    },
                    false,
                    moveOnce,
                    true
                );
            });
        }
    }

    public waterCollectiveMeterFormGroup(): AbstractControl {
        return this.form?.get('waterCollectiveMeter');
    }
}
